
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import useCarrierLookupOpenModal from '@/compositions/services/useCarrierLookupOpenModal'

export default defineComponent({
  name: 'BulkLookupFilter',
  components: {
    TopFilterButton,
    TopFilter,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  setup(props) {
    const { openDeleteModal } = useCarrierLookupOpenModal()

    return {
      openDeleteModal: () => openDeleteModal((props.selected || []).length),
    }
  },
})
