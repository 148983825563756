import { useModals } from '@/compositions/modals'

type CarrierLookup = () => {
  openViewDetailsModal: () => void
  openDeleteModal: (count: number) => void
  openContactModal: () => void
}

const useCarrierLookupOpenModal: CarrierLookup = () => {
  const { openModal } = useModals()

  const openViewDetailsModal = () => {
    openModal('carrierLookupHistoryDetails', {
      modalTitle: 'Carrier lookup history details',
    })
  }

  const openDeleteModal = (count = 1) => {
    openModal('confirmation', {
      title: `Delete carrier lookup${count > 1 ? 's' : ''}`,
      text: `Are you sure you would like to delete selected item${count > 1 ? 's' : ''}? This action cannot be undone.`,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  const openContactModal = () => {
    openModal('contact')
  }

  return {
    openViewDetailsModal,
    openDeleteModal,
    openContactModal,
  }
}

export default useCarrierLookupOpenModal
