
import { computed, defineComponent, ref } from 'vue'
import BulkLookupFilter from '@/components/pages/services/carrierLookup/bulkLookup/BulkLookupFilter.vue'
import type { StatusType, TableHeaders } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'
import { getTableData } from '@/services/tableService'
import BulkLookupTable from '@/components/pages/services/carrierLookup/bulkLookup/BulkLookupTable.vue'
import BulkLookupEmpty from '@/components/pages/services/carrierLookup/bulkLookup/BulkLookupEmpty.vue'
import { filterItemsByValues } from '@/services/utils'
import { formatDate } from '@/services/dateTimeService'

type BulkLookup = {
  fileName: string
  size: string
  status: StatusType
  dateCreated: string
}

export default defineComponent({
  name: 'ServicesCarrierBulkLookup',
  components: { BulkLookupEmpty, BulkLookupTable, BulkLookupFilter },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<BulkLookup[]>([])
    const tableItems: BulkLookup[] = getTableData('carrierLookupBulk')
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'File name', value: 'fileName' },
      { text: 'Size', value: 'size' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Date created', value: 'dateCreated', format: (val: string) => formatDate(val) },
    ])
    const filteredTableItems = computed<BulkLookup[]>(() =>
      tableItems.filter((item) => filterItemsByValues(search.value, [item.fileName, item.size]))
    )

    return {
      isEmptyMode,
      search,
      selected,
      tableItems,
      tableHeaders,
      filteredTableItems,
    }
  },
})
